import { datadogRum } from '@datadog/browser-rum';
import { DiningOptionEnum, OrderFlowEnum, ReceiveMethodEnum, StoreItemValidityEnum } from '@goparrot/common';
import type { IReadPromotionWithApplicationStateDto } from '@goparrot/promotions-sdk';
import { DelayedOrdersUtils } from '@goparrot/store-v2-sdk';
import { HowToGetIt } from '@webstore-monorepo/features/how-to-get-it';
import { OrderItemsList } from '@webstore-monorepo/features/order-items';
import { OrderNotesInput } from '@webstore-monorepo/features/order-notes-input';
import { OrderToTableSidebar } from '@webstore-monorepo/features/order-to-table';
import { OrderTotal } from '@webstore-monorepo/features/order-total';
import { Payment, useValidateCart } from '@webstore-monorepo/features/payment';
import { PromoCodeInput } from '@webstore-monorepo/features/promo-code-input';
import { Tips } from '@webstore-monorepo/features/tips';
import { useValidateCartMutation } from '@webstore-monorepo/shared/api/cart-api';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useGetFeatureFlag } from '@webstore-monorepo/shared/hooks/use-get-feature-flag';
import { getShowTip } from '@webstore-monorepo/shared/utils/cart';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { LocalStorage } from '@webstore-monorepo/shared/utils/local-storage';
import { Box } from '@webstore-monorepo/ui/box';
import { Separator } from '@webstore-monorepo/ui/separator';
import isUndefined from 'lodash/isUndefined';
import type { RefObject } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIsFetching, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { APP_PATHS } from '../../routes/paths';
import { useAppContextDispatch, useAppContextState } from '../../shared/contexts/app-context-provider';
import { useNotification } from '../../shared/contexts/notification-provider';
import { useAnalytics } from '../../shared/hooks/use-analytics';
import { useAppHistory } from '../../shared/hooks/use-app-history';
import { QueriesKeysEnum } from '../../shared/queries/queries-keys-enum';
import { LocalStorageKeysEnum } from '../../shared/services/v2';
import { getDeviceSource } from '../../shared/utils/device-source';
import { getIntlString } from '../../shared/utils/intl';
import { startAddingManualPromo } from '../../shared/utils/promotions';
import { MENU_PATHS } from '../paths';

export const PaymentPage = () => {
  const appContextDispatch = useAppContextDispatch();
  const store = useStoreState();
  const cart = useCartState();
  const cartDispatch = useCartDispatch();
  const { state: locationState } = useLocation();
  const analytics = useAnalytics();
  const notification = useNotification();
  const history = useAppHistory();
  const deviceSource = getDeviceSource();
  const appState = useAppContextState();
  const [showSelectGratuityNotification, setShowSelectGratuityNotification] = useState(false);
  const tipsRef = useRef<HTMLElement>();
  const isDigitalCart = cart?.flow === OrderFlowEnum.DIGITAL;
  const { cartScreen } = useComponentsConfig();
  const isOrderToTable = cart.diningOptionInfo.type === DiningOptionEnum.DINE_IN;
  const { isMobile } = useWindowDimensions();
  const localStorage = new LocalStorage();
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const queryClient = useQueryClient();
  const showOrderNotes = cartScreen?.[isMobile ? 'wrapperMobile' : 'wrapper']?.diningOptions?.options?.showNotes;
  const packingItemsRef = useRef<RefObject<HTMLElement>[]>([]);
  const [invalidPackingIndex, setInvalidPackingIndex] = useState(-1);
  const [isPackingValid, setIsPackingValid] = useState(true);
  const isFetching = useIsFetching();
  const isTrackCalled = useRef(false);
  const isGuestCheckoutEnabled = useGetFeatureFlag('isGuestCheckoutEnabled');
  const showTip = getShowTip(cart, store);
  const { mutateAsync: onValidateCart } = useValidateCart({
    onSuccess: ({ isOrderingAllowed }) => {
      const orderAheadAvailabilityForMethods = DelayedOrdersUtils.isOrderAheadAvailable(store);
      if (!isOrderingAllowed) {
        notification.error(
          !(orderAheadAvailabilityForMethods[ReceiveMethodEnum.PICKUP] || orderAheadAvailabilityForMethods[ReceiveMethodEnum.DELIVERY])
            ? getIntlString('payment.notification.closedStore')
            : getIntlString('payment.notification.unavailableTime'),
        );
        history.push(MENU_PATHS.order_setup);
        throw new Error(getIntlString('cart.validation.error'));
      }
    },
    onError: (error) => {
      if (!error?.message) {
        notification.error(getIntlString('cart.validation.error'));
      }
      handleStopLoading();
      analytics.track('pay_button_clicked_cart_validation_error', { error });
    },
  });
  const { mutateAsync: onValidateCartMutation } = useValidateCartMutation({
    onSuccess: (validatedCart) => {
      cartDispatch({ type: 'update', payload: validatedCart });

      if (validatedCart.validity && validatedCart.validity === StoreItemValidityEnum.SLOT_CAPACITY_EXCEEDED) {
        notification.error(getIntlString('payment.cart.slot-capacity.error'));
        analytics.track('select_dining-slot-capacity-exceeded', {});

        if (appState.isDynamicMenuEnabled) {
          history.push(MENU_PATHS.order_setup);
          throw new Error(getIntlString('cart.validation.error'));
        }
      }

      if (validatedCart.validity && validatedCart.validity === StoreItemValidityEnum.SELECTED_TIME_SLOT_UNAVAILABLE) {
        notification.error(getIntlString('payment.cart.selected-time-unavailable.error'));
        analytics.track('select_dining-slot-unavailable', {});

        if (appState.isDynamicMenuEnabled) {
          history.push(MENU_PATHS.order_setup);
          throw new Error(getIntlString('cart.validation.error'));
        }
      }

      if (validatedCart.validity && validatedCart.validity !== StoreItemValidityEnum.VALID) {
        notification.error(getIntlString('payment.notification.reviewCart'));
        analytics.track('pay_button_clicked_cart_validity_error', { validity: validatedCart.validity });
        throw new Error(getIntlString('cart.validation.error'));
      }
    },
    onError: (error) => {
      if (!error?.message) {
        notification.error(getIntlString('cart.validation.error'));
      }
      handleStopLoading();
      analytics.track('pay_button_clicked_cart_validation_error', { error });
    },
  });

  const handleStopLoading = useCallback(() => {
    setIsPaymentLoading(false);
  }, []);

  const handleCloseModal = () => {
    history.push(APP_PATHS.home);
  };

  const handleGoBack = () => {
    history.push(MENU_PATHS.cart);
  };

  useEffect(() => {
    if (!isUndefined(cart.tipsInfo?.amount)) {
      setShowSelectGratuityNotification(false);
    }
  }, [cart]);

  const handlePostCharge = useCallback(async () => {
    // The case should be removed after resetting the cart
    localStorage.remove(LocalStorageKeysEnum.IS_DINING_OPTION_SELECTED_BY_USER);
    localStorage.remove(LocalStorageKeysEnum.IS_DEFAULT_PACKING_INSTRUCTION_SET);

    await queryClient.refetchQueries(QueriesKeysEnum.lastOrders);
    if (isPaymentLoading) {
      handleStopLoading();
    }
    history.push(MENU_PATHS.order_complete.replace(':orderId', cart.uuid));
  }, [cart.uuid, handleStopLoading, history, isPaymentLoading, localStorage, queryClient]);

  const handlePackingValidationChange = (isPackingInstructionValid: boolean, invalidGroupIndex: number) => {
    setIsPackingValid(isPackingInstructionValid);
    setInvalidPackingIndex(invalidGroupIndex);
  };

  const handlePayClick = useCallback(async () => {
    try {
      setIsPaymentLoading(true);
      if (!isDigitalCart && showSelectGratuityNotification) {
        tipsRef.current?.scrollIntoView({ behavior: 'smooth' });
        handleStopLoading();
        throw undefined;
      }

      if (!isDigitalCart && isUndefined(cart.tipsInfo?.amount) && showTip) {
        setShowSelectGratuityNotification(true);
        tipsRef.current?.scrollIntoView({ behavior: 'smooth' });
        handleStopLoading();
        throw undefined;
      }
      if (invalidPackingIndex > -1) {
        packingItemsRef.current[invalidPackingIndex].current?.scrollIntoView({ behavior: 'smooth' });
        handleStopLoading();
        throw undefined; // used to stop the execution of the function
      }
      analytics.track('pay_button_click', { deviceSource });
      await onValidateCartMutation(isDigitalCart);
      await onValidateCart(cart._id);
      analytics.track('pay_button_clicked_start_charging', {});
    } catch (e) {
      throw undefined; // used to stop the execution of the function
    }
  }, [analytics, deviceSource, invalidPackingIndex, onValidateCart, onValidateCartMutation]);

  const handleStartAddingManualPromotion = (validPromotion: IReadPromotionWithApplicationStateDto) => {
    appContextDispatch({ type: 'update', payload: { promotion: validPromotion } });
    startAddingManualPromo(validPromotion.promotion, history, store.storeId, validPromotion.availableApplicationCount);
  };

  const handleRedeemRewards = () => {
    localStorage.set('loyaltyFrom', 'payment');
    history.push(MENU_PATHS.loyalty_info);
  };

  const handleNavigateToLogin = () => {
    appContextDispatch({ type: 'update', payload: { nextModal: MENU_PATHS.cart } });
    history.push(MENU_PATHS.login);
  };

  useEffect(() => {
    if (!isTrackCalled.current) {
      isTrackCalled.current = true;
      analytics.track('checkout_initiated');
      datadogRum.addAction('checkout_initiated');
    }
  }, [analytics]);

  useEffect(() => {
    if ((Object.hasOwn(locationState, 'login') && locationState.login !== 'guest') || (Object.hasOwn(locationState, 'fromCart') && !locationState.fromCart)) {
      history.push(MENU_PATHS.cart);
    }
  }, [locationState, isGuestCheckoutEnabled]);

  return (
    <div style={{ padding: 0, minHeight: '100%' }}>
      <Payment orderId={cart._id} onPostCharge={handlePostCharge} onPaymentUnauthorized={handleNavigateToLogin}>
        <Payment.Header onClose={handleCloseModal} title="Checkout" />
        <Payment.Content>
          {!isDigitalCart ? (
            <>
              {isOrderToTable ? <OrderToTableSidebar isReadOnly /> : <HowToGetIt isReadOnly />}
              <Separator />
            </>
          ) : null}
          <OrderItemsList isReadOnly isDefaultOpen={false} />
          {!isDigitalCart ? (
            <Box mb={4}>
              <Payment.PackingInstructions isReadOnly={isPaymentLoading} itemRef={packingItemsRef} onValidationChange={handlePackingValidationChange} />
            </Box>
          ) : null}
          <PromoCodeInput onStartAddingManualPromotion={handleStartAddingManualPromotion} />
          {!isDigitalCart ? <Separator /> : null}
          <Payment.ExpressCheckout isPaymentLoading={isPaymentLoading} onStopLoading={handleStopLoading} onPayClick={handlePayClick} />
          <Payment.GuestCheckoutFormBlock isLoading={isPaymentLoading} onPressSignIn={handleNavigateToLogin} />
          <Box mb={4}>
            <Payment.PaymentBlock isPaymentLoading={isPaymentLoading} />
          </Box>
          {!isDigitalCart ? <Separator /> : null}
          <Payment.GiftCards isPaymentLoading={isPaymentLoading} />
          <Payment.RedeemRewardsBlock onHandleSelectReward={handleRedeemRewards} />
          {showTip ? <Tips ref={tipsRef} isDisabled={isPaymentLoading} showSelectGratuityNotification={showSelectGratuityNotification} /> : null}
          {isDigitalCart ? null : showOrderNotes ? (
            <>
              <OrderNotesInput />
              <Separator />
            </>
          ) : (
            <Separator />
          )}
          <OrderTotal placement="content" showTip />
        </Payment.Content>
        <Payment.Footer
          onStopLoading={handleStopLoading}
          onBack={handleGoBack}
          onProceed={handlePayClick}
          isDisabled={!isPackingValid}
          isLoading={isFetching >= 1 || isPaymentLoading}
        />
      </Payment>
    </div>
  );
};
