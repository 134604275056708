import type {
  BackgroundColorProps,
  BorderProps,
  ColorProps,
  LayoutProps,
  SpacingProps,
  SpacingShorthandProps,
  TextProps,
  VariantProps,
} from '@shopify/restyle';
import { color, createRestyleComponent } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useFocusRing } from '@webstore-monorepo/shared/hooks/use-focus-ring';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { TextPropsExtended } from '@webstore-monorepo/ui/text';
import { Text } from '@webstore-monorepo/ui/text';
import React, { forwardRef, memo, useRef } from 'react';
import { Pressable } from 'react-native';

import { CheckboxIcon } from './CheckboxIcon';

const Container = createRestyleComponent<
  VariantProps<Theme, 'buttonVariants'> & ColorProps<Theme> & Pick<TextPropsExtended, 'fontSize'> & React.ComponentProps<typeof Box>,
  Theme
>([color], Box);

type boxProps = LayoutProps<Theme> & SpacingProps<Theme> & SpacingShorthandProps<Theme> & BackgroundColorProps<Theme> & BorderProps<Theme>;

export type CheckboxProps = React.ComponentProps<typeof Container> & {
  onPress: () => void;
  checked?: boolean;
  label?: string | React.ReactNode;
  fontSize?: string;
  isDisabled?: boolean;
  labelPlacement?: 'before' | 'after';
  uncheckedBorderColor?: keyof Theme['colors'];
  uncheckedBackgroundColor?: keyof Theme['colors'];
  checkedBackgroundColor?: keyof Theme['colors'];
  tickColor?: keyof Theme['colors'];
  fullWidth?: boolean;
  containerStyles?: boxProps;
  checkboxStyles?: boxProps;
  testID?: string;
  id?: string;
  labelStyles?: TextProps<Theme> & ColorProps<Theme> & keyof Theme['fontSizes'];
};

export const Checkbox = memo(
  forwardRef(
    (
      {
        onPress,
        label,
        checkedBackgroundColor,
        tickColor,
        uncheckedBorderColor,
        uncheckedBackgroundColor,
        isDisabled = false,
        checked = false,
        fullWidth = false,
        labelPlacement = 'after',
        containerStyles,
        checkboxStyles,
        labelStyles,
        id,
        testID,
      }: CheckboxProps,
      forwardRef,
    ) => {
      const ref = useRef();
      const { hoverProps } = useHover({}, forwardRef ?? ref);
      const { checkbox } = useComponentsConfig()?.sharedComponents ?? {};
      const {
        borderColor: configBorderColor,
        backgroundColor: configBackgroundColor,
        activeColor: configActiveColor,
        activeTickColor,
      } = checkbox?.options ?? {};
      const { isFocusVisible, focusProps } = useFocusRing();
      const borderColor = uncheckedBorderColor || configBorderColor || 'gray300';
      const activeColor = checkedBackgroundColor || configActiveColor || 'primaryColor';
      const backgroundColor = uncheckedBackgroundColor || configBackgroundColor || 'white';
      const tickFill = tickColor || activeTickColor || 'white';

      return (
        <Box alignItems="flex-start" width={fullWidth ? '100%' : undefined} id={id} testID={testID}>
          <Pressable
            style={{
              width: fullWidth ? '100%' : undefined,
            }}
            accessibilityRole="checkbox"
            disabled={isDisabled}
            onPress={onPress}
            {...focusProps}
          >
            <Box flexDirection={labelPlacement === 'after' ? 'row' : 'row-reverse'} alignItems="center" {...checkbox?.style} {...containerStyles}>
              <Box
                width={20}
                height={20}
                borderColor={checked ? activeColor : borderColor}
                backgroundColor={checked ? activeColor : backgroundColor}
                opacity={isDisabled ? 0.6 : 1}
                borderWidth={2}
                marginRight={labelPlacement === 'after' ? 3 : 'none'}
                marginLeft={labelPlacement === 'before' ? 3 : 'none'}
                borderRadius="xs"
                {...checkboxStyles}
                {...hoverProps}
                {...(isFocusVisible
                  ? {
                      outlineColor: activeColor,
                      outlineWidth: 2,
                      outlineStyle: 'solid',
                      outlineOffset: 0,
                    }
                  : {})}
              >
                {checked ? <CheckboxIcon testID="check-icon" fill={tickFill} stroke="white" width={16} height={16} /> : null}
              </Box>
              <Text variant="button" {...checkbox?.label?.style} {...(typeof labelStyles === 'object' ? labelStyles : {})} opacity={isDisabled ? 0.6 : 1}>
                {label}
              </Text>
            </Box>
          </Pressable>
        </Box>
      );
    },
  ),
);
