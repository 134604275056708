import styled from 'styled-components';

export const Container = styled.div``;

export const StickyContainer = styled.div<{ backgroundColor?: string }>`
  display: block;
  position: sticky;
  z-index: 99;
  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor};
`;
