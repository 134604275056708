import type { WeeklyWorkingSchedule } from '@goparrot/store-v2-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { DayName } from '@webstore-monorepo/shared/enums';
import { getFormattedHours } from '@webstore-monorepo/shared/utils/store';
import type { FC } from 'react';
import React from 'react';

import { ScheduleItemBase } from './ScheduleItemBase';

type ScheduleItemProps = {
  hours: WeeklyWorkingSchedule;
  description?: string;
};
export const ScheduleItem: FC<ScheduleItemProps> = ({ hours, description }) => {
  const { heroBlock } = useComponentsConfig();
  const { heroBlockOptions = {} } = heroBlock?.wrapper?.options ?? {};
  const { dayToSeparator = ' - ', dayToTimeSeparator = ' | ' } = heroBlockOptions ?? {};
  const dayNameDayFrom = DayName[hours.dayFrom as keyof typeof DayName];
  const dayNameDayTo = DayName[hours.dayTo as keyof typeof DayName];

  if (hours.from === 'closed') return null;
  return !description ? (
    <ScheduleItemBase hours={hours} description={description} />
  ) : (
    <div data-testid="rendered-description">
      <span>
        {dayNameDayFrom}
        {hours.dayTo && `${dayToSeparator}${dayNameDayTo}`}
        {dayToTimeSeparator}
      </span>
      <span>{getFormattedHours(hours)}</span>
    </div>
  );
};
