import type { WeeklyWorkingSchedule } from '@goparrot/store-v2-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { DayName } from '@webstore-monorepo/shared/enums';
import { getFormattedHours } from '@webstore-monorepo/shared/utils/store';
import { findCorrectAlign } from '@webstore-monorepo/shared/utils/text';
import { Box } from '@webstore-monorepo/ui/box';
import type { FC } from 'react';
import React from 'react';

import { HeroBlockText } from '../hero-block-text';

type ScheduleItemBaseProps = {
  hours: WeeklyWorkingSchedule;
  description?: string;
};
export const ScheduleItemBase: FC<ScheduleItemBaseProps> = ({ hours, description }) => {
  const { heroBlock } = useComponentsConfig();
  const { heroBlockOptions = {} } = heroBlock?.wrapper?.options ?? {};
  const { dayToSeparator = ' - ', dayToTimeSeparator = ' | ' } = heroBlockOptions ?? {};
  const dayNameDayFrom = DayName[hours.dayFrom as keyof typeof DayName];
  const dayNameDayTo = DayName[hours.dayTo as keyof typeof DayName];
  if (hours.from === 'closed') return null;
  return !description ? (
    <Box
      key={`${hours.dayFrom}-${hours.dayTo}`}
      flexDirection="row"
      alignItems="center"
      testID="schedule"
      justifyContent={findCorrectAlign(heroBlock?.wrapper?.text?.style?.textAlign)}
    >
      <HeroBlockText
        {...heroBlock?.descriptionBlock?.text?.style}
        fontStyle="italic"
        textTransform="uppercase"
        fontWeight="700"
        {...heroBlock?.schedule?.text?.style}
        {...heroBlock?.schedule?.days?.style}
      >
        {dayNameDayFrom}
        {hours.dayTo && `${dayToSeparator}${dayNameDayTo}`}
        {dayToTimeSeparator}
      </HeroBlockText>
      <HeroBlockText
        {...heroBlock?.descriptionBlock?.text?.style}
        fontStyle="italic"
        fontWeight="400"
        {...heroBlock?.schedule?.text?.style}
        {...heroBlock?.schedule?.hours?.style}
      >
        {getFormattedHours(hours)}
      </HeroBlockText>
    </Box>
  ) : null;
};
