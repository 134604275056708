import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import isEmpty from 'lodash/isEmpty';
import type { PropsWithChildren } from 'react';
import React, { createContext, useContext } from 'react';

import { getDigitalWalletsFeaturesFlags } from './utils';

interface IFeaturesFlagContext extends IDigitalWalletsFeaturesFlags {
  isPayByCashEnabled: boolean;
  giftcardsPaymentsEnabled: boolean;
  isStrongCustomerAuthenticationEnabled: boolean;
  featuresFlagsLoaded: boolean;
}

export interface IDigitalWalletsFeaturesFlags {
  isGooglePayEnabled: boolean;
  isApplePayEnabled: boolean;
}

export const FeaturesFlagsContext = createContext<IFeaturesFlagContext>({
  isApplePayEnabled: false,
  isGooglePayEnabled: false,
  isPayByCashEnabled: false,
  giftcardsPaymentsEnabled: false,
  isStrongCustomerAuthenticationEnabled: false,
  featuresFlagsLoaded: false,
});

export const FeatureFlagsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const store = useStoreState();
  const merchant = useMerchantState();
  const storeFeaturesFlags = store?.featuresFlags;
  const merchantFeaturesFlags = merchant?.featuresFlags;

  const mergedFeaturesFlags = {
    isApplePayEnabled: !!merchantFeaturesFlags?.isApplePayEnabled && !!storeFeaturesFlags?.isApplePayEnabled,
    isApplePayMobileEnabled: !!merchantFeaturesFlags?.isApplePayMobileEnabled && !!storeFeaturesFlags?.isApplePayMobileEnabled,
    isGooglePayEnabled: !!merchantFeaturesFlags?.isGooglePayEnabled && !!storeFeaturesFlags?.isGooglePayEnabled,
    isGooglePayMobileAndroidEnabled: !!merchantFeaturesFlags?.isGooglePayMobileAndroidEnabled && !!storeFeaturesFlags?.isGooglePayMobileAndroidEnabled,
    isGooglePayMobileIOSEnabled: !!merchantFeaturesFlags?.isGooglePayMobileIOSEnabled && !!storeFeaturesFlags?.isGooglePayMobileIOSEnabled,
    isPayByCashEnabled: !!storeFeaturesFlags?.isPayByCashEnabled,
    giftcardsPaymentsEnabled: !!merchantFeaturesFlags?.giftcardsEnabled && !!storeFeaturesFlags?.giftcardsPaymentsEnabled,
    isStrongCustomerAuthenticationEnabled: !!storeFeaturesFlags?.isStrongCustomerAuthenticationEnabled,
  };

  const digitalWalletsFeaturesFlags = getDigitalWalletsFeaturesFlags(mergedFeaturesFlags);

  const paymentFeaturesFlagsContext = {
    featuresFlagsLoaded: !isEmpty(store ?? {}),
    isPayByCashEnabled: mergedFeaturesFlags.isPayByCashEnabled,
    giftcardsPaymentsEnabled: mergedFeaturesFlags.giftcardsPaymentsEnabled,
    isStrongCustomerAuthenticationEnabled: mergedFeaturesFlags.isStrongCustomerAuthenticationEnabled,
    ...digitalWalletsFeaturesFlags,
  };

  return <FeaturesFlagsContext.Provider value={paymentFeaturesFlagsContext}>{children}</FeaturesFlagsContext.Provider>;
};

export const useFeatureFlags = () => {
  const context = useContext(FeaturesFlagsContext);
  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
  }
  return context;
};
