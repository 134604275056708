import { DiningOptionEnum, recursiveClean } from '@goparrot/common';
import type { ReadWebStoreComponentsDto } from '@goparrot/webstore-sdk';
import { SocialMediaDisplayAreaEnum, StoreItemVariantDesktopEnum, StoreItemVariantMobileEnum } from '@goparrot/webstore-sdk';
import deepmerge from 'deepmerge';

import {
  convertBoxShadowToRnw,
  convertLegacyFontSize,
  convertShorthandBorderToFull,
  extractAndMapToTheme,
  extractCSSValue,
  findCorrectAlign,
  getSpacingValues,
} from './theme-utils';

export const getMergedComponentsConfigurations = (): ReadWebStoreComponentsDto => {
  const webMerchantDto = window.SSRContext.webMerchant;
  const webMerchant = recursiveClean(webMerchantDto, { removeEmptyString: true });
  const { components: storeLevelComponents = {} } = recursiveClean(window.SSRContext.webstore, { removeEmptyString: true });

  const { components: merchantLevelComponents = {} } = webMerchant;
  const mergedConfig: ReadWebStoreComponentsDto = deepmerge(merchantLevelComponents, storeLevelComponents);
  const webstoreLegacyTheme = window.SSRContext.webstore.metadata.theme;
  const metadata = window.SSRContext.webstore.metadata ?? {};

  const { ':hover': hoveredMenuConceptsItemStyles = {}, ...restMenuConceptsItemStyles } = webstoreLegacyTheme?.menuConcepts?.bar?.item?.sm ?? {};

  const legacyConfig: ReadWebStoreComponentsDto = {
    header: {
      wrapper: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.headerColor),
        },
      },
      headerWrapper: {
        style: {
          // @ts-expect-error
          height: extractCSSValue(webstoreLegacyTheme.headerHeight),
        },
      },
      text: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          color: extractCSSValue(webstoreLegacyTheme.textBase),
          // @ts-expect-error wrong key for the theme, only for legacy
          fontSize: extractCSSValue(webstoreLegacyTheme.headerFontSize),
          // @ts-expect-error wrong key for the theme, only for legacy
          fontFamily: extractCSSValue(webstoreLegacyTheme.ffHeader),
          // @ts-expect-error wrong key for the theme, only for legacy
          fontWeight: extractCSSValue(webstoreLegacyTheme.headerFontWeight),
          // @ts-expect-error wrong key for the theme, only for legacy
          textTransform: extractCSSValue(webstoreLegacyTheme.headerTextTransform),
        },
        hover: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.headerNavHover, extractCSSValue(webstoreLegacyTheme.mainHoverColor)),
          },
        },
      },
      infoBar: {
        options: {
          emergencyStop: metadata?.customMessages?.topBarMessages?.emergencyStop,
          storeIsClosed: metadata?.customMessages?.topBarMessages?.storeIsClosed,
          orderAheadAvailable: metadata?.customMessages?.topBarMessages?.oredrAheadAvailable,
        },
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.headerBannerBg),
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.headerBannerColor),
          },
        },
      },
      topNavBar: {
        style: {
          // @ts-expect-error
          height: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.height),
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.backGround),
          // @ts-expect-error wrong key for the theme, only for legacy
          justifyContent: findCorrectAlign(extractCSSValue(webstoreLegacyTheme.headerTopNavBar.align)),
        },
        item: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.textColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.textTransform),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.fontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.fontWeight),
          },
        },
        itemHover: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.textColorHover),
          },
        },
        activeItem: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            shadowColor: extractCSSValue(webstoreLegacyTheme?.headerTopNavBar?.activeLink.underlineColor, extractCSSValue(webstoreLegacyTheme.navUnderline)),
          },
        },
        activeItemText: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.headerTopNavBar?.activeLink.textColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.headerTopNavBar?.activeLink.fontWeight),
          },
        },
        options: {
          isShown: metadata.headerTopNavBar?.isShown,
          links: metadata.headerTopNavBar?.links,
        },
      },
      headerLinks: {
        customLink: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.headerCustomButtonColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.headerCustomButtonFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.headerCustomButtonBg),
          },
          options: {
            openInNewTab: metadata.customHeaderButton?.openInNewTab,
            showAsButton: metadata.customHeaderButton?.showAsButton,
            title: metadata.customHeaderButton?.title,
            url: metadata.customHeaderButton?.url,
          },
        },
        customLinkHover: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.headerCustomButtonColorHover),
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.headerCustomButtonBgHover, extractCSSValue(webstoreLegacyTheme.mainHoverColor)),
          },
        },
        options: {
          homeButtonText: metadata.homeButtonText,
          homeButtonUrl: metadata.homeButtonUrl,
        },
      },
      cart: {
        text: {
          ...storeLevelComponents.header?.cart?.text,
        },
        hover: {
          ...storeLevelComponents.header?.cart?.hover,
        },
        options: {
          icon: metadata.cartButton?.icon,
          label: metadata.cartButton?.name,
        },
      },
      circleBadge: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.cartHeaderCountBg),
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.cartHeaderCountColor),
          },
        },
      },
    },
    headerLogoBlock: {
      wrapper: {
        image: {
          style: {
            // @ts-expect-error
            height: extractCSSValue(webstoreLegacyTheme.logoHeight)
              ? extractCSSValue(webstoreLegacyTheme.logoHeight)
              : extractCSSValue(webstoreLegacyTheme.logoSize),
            // @ts-expect-error
            width: extractCSSValue(webstoreLegacyTheme.logoWidth),
            // @ts-expect-error
            maxWidth: extractCSSValue(webstoreLegacyTheme.logoWidth),
            // @ts-expect-error
            maxHeight: extractCSSValue(webstoreLegacyTheme.logoHeight)
              ? extractCSSValue(webstoreLegacyTheme.logoHeight)
              : extractCSSValue(webstoreLegacyTheme.logoSize),
          },
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.mainColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.titlesWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.titleStyle),
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.titlesTransform),
          },
        },
        options: {
          logoPath: metadata.headerLogo,
          url: metadata.headerLogoUrl?.url,
          openInNewTab: metadata.headerLogoUrl?.openInNewTab,
        },
      },
      wrapperMobile: {
        image: {
          style: {
            // @ts-expect-error
            height: extractCSSValue(webstoreLegacyTheme.mobileLogoWidth)
              ? extractCSSValue(webstoreLegacyTheme.mobileLogoWidth)
              : extractCSSValue(webstoreLegacyTheme.logoSize),
            // @ts-expect-error
            width: extractCSSValue(webstoreLegacyTheme.mobileLogoWidth),
            // @ts-expect-error
            maxWidth: extractCSSValue(webstoreLegacyTheme.mobileLogoWidth),
            // @ts-expect-error
            maxHeight: extractCSSValue(webstoreLegacyTheme.mobileLogoWidth)
              ? extractCSSValue(webstoreLegacyTheme.mobileLogoWidth)
              : extractCSSValue(webstoreLegacyTheme.logoSize),
          },
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.mainColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.titlesWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.titleStyle),
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.titlesTransform),
          },
        },
        options: {
          logoPath: metadata.headerLogo,
          url: metadata.headerLogoUrl?.url,
          openInNewTab: metadata.headerLogoUrl?.openInNewTab,
        },
      },
    },
    footer: {
      wrapper: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.footerBg),
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.footerTextColor),
          },
        },
        options: {
          disclaimer: metadata.customMessages.footerPage,
          contactsTemplate: metadata.footer?.contactsTemplate,
        },
      },
    },
    socialMedia: {
      wrapper: {
        [SocialMediaDisplayAreaEnum.FOOTER]: {
          text: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.socialMedia?.FOOTER?.textColor),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontSize: extractCSSValue(webstoreLegacyTheme.socialMedia?.FOOTER?.fontSize),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontWeight: extractCSSValue(webstoreLegacyTheme.socialMedia?.FOOTER?.fontWeight),
              // @ts-expect-error wrong key for the theme, only for legacy
              textTransform: extractCSSValue(webstoreLegacyTheme.socialMedia?.FOOTER?.textTransform),
            },
          },
          icon: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.socialMedia?.FOOTER?.iconsColor),
            },
          },
        },
        [SocialMediaDisplayAreaEnum.SIDEBAR]: {
          text: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.socialMedia?.SIDEBAR?.textColor),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontSize: extractCSSValue(webstoreLegacyTheme.socialMedia?.SIDEBAR?.fontSize),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontWeight: extractCSSValue(webstoreLegacyTheme.socialMedia?.SIDEBAR?.fontWeight),
              // @ts-expect-error wrong key for the theme, only for legacy
              textTransform: extractCSSValue(webstoreLegacyTheme.socialMedia?.SIDEBAR?.textTransform),
            },
          },
          icon: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.socialMedia?.SIDEBAR?.iconsColor),
            },
          },
        },
        options: {
          isShown: metadata.socialMedia?.isShown,
          links: metadata.socialMedia?.links,
          text: metadata.socialMedia?.text,
          displayAreas: metadata.socialMedia?.displayAreas,
        },
      },
    },
    loyaltyBadge: {
      wrapper: {
        options: {
          rewardIcon: metadata.iframeLoyalty?.rewardIcon,
        },
      },
    },
    heroBlock: {
      wrapper: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          justifyContent: findCorrectAlign(extractCSSValue(webstoreLegacyTheme.heroBlockContentAlign)),
          // @ts-expect-error wrong key for the theme, only for legacy
          opacity: extractCSSValue(webstoreLegacyTheme.heroblockBannerBrightness),
          // @ts-expect-error
          height: webstoreLegacyTheme?.heroblockBannerHeight === 'inherit' ? 'auto' : extractCSSValue(webstoreLegacyTheme.heroblockBannerHeight),
        },
        backgroundWrapper: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            paddingBottom: extractCSSValue(webstoreLegacyTheme.heroBlockBannerRatio),
          },
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.heroblockTextColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign: extractCSSValue(webstoreLegacyTheme.heroblockTextAlign),
          },
        },
        options: {
          title: metadata.heroBlock?.title,
          subtitle: metadata.heroBlock?.subtitle,
          description: metadata.heroBlock?.description,
          backgroundImage: metadata.heroBlock?.backgroundImage,
          customDescription: metadata.heroBlock?.customDescription,
          heroBlockOptions: metadata.heroBlock?.heroBlockOptions,
          showMobileHeroBlock: metadata.heroBlock?.showMobileHeroBlock,
          showMobileAddressBlock: metadata.heroBlock?.showMobileAddressBlock,
          backgroundImageMobile: metadata.heroBlock?.backgroundImageMobile,
        },
      },
      descriptionBlock: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          justifyContent: extractCSSValue(webstoreLegacyTheme.heroblockJustifyContent),
          ...getSpacingValues(webstoreLegacyTheme.heroblockMargin, 'margin'),
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.heroblockTextColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.heroblockFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.heroblockDescriptionFontWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.ffHeroBlockDescription),
            // @ts-expect-error wrong key for the theme, only for legacy
            lineHeight: extractCSSValue(webstoreLegacyTheme.heroblockLineHeight),
          },
        },
        title: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.ffHeroBlockTitle, extractCSSValue(webstoreLegacyTheme.ffBase)),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.heroblockTitleFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.heroblockTitleFontWeight),
          },
        },
      },
      mobile: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.heroBlockBackgroundMobile),
          // @ts-expect-error
          height: webstoreLegacyTheme?.heroblockBannerHeight === 'inherit' ? 'auto' : extractCSSValue(webstoreLegacyTheme.heroblockMobileHeight),
        },
      },
      schedule: {
        style: {
          ...getSpacingValues(webstoreLegacyTheme.heroBlockScheduleMargin, 'margin'),
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleFontWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleFontStyle),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleFF) ?? extractCSSValue(webstoreLegacyTheme.ffBase),
          },
        },
        days: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleDaysColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleDaysFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleDaysFontWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleDaysTextTransform),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleDaysFontStyle),
          },
        },
        hours: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleHoursColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleHoursFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleHoursFontWeight),
          },
        },
        plainText: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.heroBlockPlainTextFF) || extractCSSValue(webstoreLegacyTheme.ffBase),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.heroBlockPlainTexFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.heroBlockPlainTexFontWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.heroBlockPlainTexFontStyle),
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.heroBlockPlainTexColor),
            ...getSpacingValues(webstoreLegacyTheme.heroBlockPlainTexMargin, 'margin'),
          },
        },
      },
    },
    orderToTableBar: {
      wrapper: {
        options: {
          text: metadata.orderToTable.text,
          subtext: metadata.orderToTable.subtext,
          hideText: metadata.orderToTable.hideText,
          hideSubtext: metadata.orderToTable.hideSubtext,
          badgeIconUrl: metadata.orderToTable.badgeIconUrl,
          componentsOrder: metadata.orderToTable.componentsOrder,
        },
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.orderToTable.backgroundColor),
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.orderToTable.textColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.orderToTable.fontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.orderToTable.fontWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.orderToTable.textTransform),
          },
        },
        subtext: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.orderToTable.textColor),
            fontSize: convertLegacyFontSize(extractCSSValue(webstoreLegacyTheme.orderToTable.fontSize), 'lower', 1),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.orderToTable.fontWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.orderToTable.textTransform),
          },
        },
      },
      badge: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          color: extractCSSValue(webstoreLegacyTheme.orderToTable.badge.color),
          // @ts-expect-error wrong key for the theme, only for legacy
          fontSize: extractCSSValue(webstoreLegacyTheme.orderToTable.fontSize),
          // @ts-expect-error wrong key for the theme, only for legacy
          fontWeight: extractCSSValue(webstoreLegacyTheme.orderToTable.fontWeight),
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.orderToTable.badge.backgroundColor),
        },
      },
      icon: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          color: extractCSSValue(webstoreLegacyTheme.orderToTable.iconColor),
        },
      },
    },
    promotionsBar: {
      item: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.promotionsBar.background),
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.promotionsBar.color),
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.promotionsBar.textTransform),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.promotionsBar.fontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.promotionsBar.weight),
          },
        },
      },
    },
    orderSetupStatusBar: {
      wrapper: {
        options: {
          showASAPOrderPrepTime: metadata.showASAPOrderPreptime,
          hideDelayedOrderTime: metadata.hideDelayedOrderTime,
          outOfDeliveryText: metadata.customMessages?.deliveryAddress?.outOfDeliveryMessage?.newAddress,
        },
        orderType: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.orderStatusBanner.background),
          },
        },
        dateTime: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.orderStatusBanner.background),
          },
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.orderStatusBanner.color),
          },
        },
      },
    },
    menuConceptsBar: {
      wrapper: {
        style: {
          ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.bar?.container?.sm),
        },
        item: {
          style: {
            ...extractAndMapToTheme(restMenuConceptsItemStyles),
          },
        },
        itemActive: {
          style: {
            borderColor: 'primaryColor',
            shadowColor: 'primaryColor',
            ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.bar?.activeItem?.sm),
          },
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.navColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.menuTextFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.navWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.ffTopCategories),
            ...extractAndMapToTheme(restMenuConceptsItemStyles),
          },
        },
        textHover: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.navColorHover),
            ...extractAndMapToTheme(restMenuConceptsItemStyles),
          },
        },
        itemHover: {
          style: {
            ...extractAndMapToTheme(hoveredMenuConceptsItemStyles),
          },
        },
      },
      block: {
        options: {
          showConceptBlock: metadata.menuConcepts?.showConceptBlock,
        },
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.navBg),
          ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.block?.container?.sm),
        },
        container: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.container?.sm),
          },
        },
        title: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.title?.sm),
          },
        },
        description: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.description?.sm),
          },
        },
        logoContainer: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.logoContainer?.sm),
          },
        },
      },
      blockMobile: {
        options: {
          showConceptBlock: metadata.menuConcepts?.showConceptBlock,
        },
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.navBg),
          ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.block?.container?.sm),
        },
        container: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.container?.sm),
          },
        },
        title: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.title?.sm),
          },
        },
        description: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.description?.sm),
          },
        },
        logoContainer: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            ...extractAndMapToTheme(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.logoContainer?.sm),
          },
        },
      },
    },
    categoriesNavBar: {
      wrapper: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.navBg),
        },
        options: {
          show: !metadata?.navigation?.hide,
        },
        menuBlock: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            justifyContent: extractCSSValue(webstoreLegacyTheme.menuPosition, 'center'),
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.navColor),
          },
        },
        item: {
          text: {
            style: {
              textTransform: extractCSSValue(webstoreLegacyTheme.menuTextTransform),
              fontSize: extractCSSValue(webstoreLegacyTheme.menuTextFontSize),
              color: extractCSSValue(webstoreLegacyTheme.navColor),
              fontWeight: extractCSSValue(webstoreLegacyTheme.navWeight),
              fontFamily: extractCSSValue(webstoreLegacyTheme.ffTopCategories),
            },
          },
          iconBlock: {
            style: {
              borderColor: extractCSSValue(webstoreLegacyTheme.navColor),
            },
            text: {
              style: {
                color: extractCSSValue(webstoreLegacyTheme.navColor),
              },
            },
          },
        },
        selectedItem: {
          iconBlock: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              borderColor: extractCSSValue(webstoreLegacyTheme.navColorHover),
            },
            text: {
              style: {
                // @ts-expect-error wrong key for the theme, only for legacy
                color: extractCSSValue(webstoreLegacyTheme.navColorHover),
              },
            },
          },
          text: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.navColorHover),
            },
          },
          underlineBlock: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              backgroundColor: extractCSSValue(webstoreLegacyTheme.navUnderline),
            },
          },
        },
      },
      searchWrapper: {
        options: {
          show: metadata?.navigationBar?.shown,
        },
        searchInput: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.storeitemTitleColor),
          },
        },
      },
    },
    menuList: {
      wrapper: {
        options: {
          // @ts-expect-error wrong key for the theme, only for legacy
          verticalGap: extractCSSValue(webstoreLegacyTheme.menuVerticalGapDesktop),
          // @ts-expect-error wrong key for the theme, only for legacy
          horizontalGap: extractCSSValue(webstoreLegacyTheme.menuHorizontalGap),
          showImages: !metadata.hideDesktopMenuItemImage,
          columnsNumberDesktop: webstoreLegacyTheme.menuColumnsNumberDesktop,
        },
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          justifyContent: extractCSSValue(webstoreLegacyTheme.menuItemsAlign),
        },
        emptyMenuText: {
          style: {
            ...extractAndMapToTheme(webstoreLegacyTheme.menuList?.emptyMenuText?.lg),
          },
          options: {
            text: metadata.customMessages?.menuList?.emptyMenuText,
          },
        },
      },
      wrapperMobile: {
        options: {
          // @ts-expect-error wrong key for the theme, only for legacy
          verticalGap: extractCSSValue(webstoreLegacyTheme.menuVerticalGap),
          showDivider: !!webstoreLegacyTheme.menuItemsDivider,
          columnsNumberMobile: webstoreLegacyTheme.menuColumnsNumberMobile,
          // @ts-expect-error wrong key for the theme, only for legacy
          horizontalGap: extractCSSValue(webstoreLegacyTheme.mobileMenuHorizontalGap),
          showImages: !metadata.hideMobileMenuItemImage,
        },
        divider: {
          // @ts-expect-error wrong key for the theme, only for legacy
          style: {
            ...convertShorthandBorderToFull(webstoreLegacyTheme.menuItemsDivider, 'bottom'),
          },
        },
        emptyMenuText: {
          style: {
            ...extractAndMapToTheme(webstoreLegacyTheme.menuList?.emptyMenuText?.sm),
          },
          options: {
            text: metadata.customMessages?.menuList?.emptyMenuText,
          },
        },
      },
    },
    menuItemCard: {
      wrapper: {
        options: {
          boxShadow: webstoreLegacyTheme.storeitemBoxShadow,
          hoverBoxShadow: webstoreLegacyTheme.storeitemBoxShadowHover,
          showZeroPrice: metadata.storeItem?.showZeroPrice,
          showTextGradient: metadata.storeItem?.showTextGradient,
          variant: metadata.storeItem?.variant?.desktop,
          itemDescriptionFormat: {
            characterCount: webstoreLegacyTheme.itemDescriptionFormat.desktop.characterCount,
            ellipsis: webstoreLegacyTheme.itemDescriptionFormat.desktop.ellipsis,
            lineCount: webstoreLegacyTheme.itemDescriptionFormat.desktop.lineCount,
          },
        },
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.storeitemBackgroundColor) || extractCSSValue(webstoreLegacyTheme.mainColor),
          border: webstoreLegacyTheme.storeitemBorder,
          ...convertShorthandBorderToFull(webstoreLegacyTheme.storeitemBorder),
          // @ts-expect-error wrong key for the theme, only for legacy
          borderColor: extractCSSValue(webstoreLegacyTheme.storeitemBorderColor),
          ...convertBoxShadowToRnw(extractCSSValue(webstoreLegacyTheme.storeitemBoxShadow)),
        },
      },
      wrapperHover: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          borderColor: extractCSSValue(webstoreLegacyTheme.storeitemBorderHoverColor),
          ...convertBoxShadowToRnw(extractCSSValue(webstoreLegacyTheme.storeitemBoxShadowHover)),
        },
      },
      thumbnailBlockWrapper: {
        style: {
          // @ts-expect-error
          flexBasis: extractCSSValue(webstoreLegacyTheme.storeitemImageWidthDesktop),
        },
        block: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            borderRadius: extractCSSValue(webstoreLegacyTheme.storeitemImageBorderRadiusDesktop),
          },
        },
      },
      content: {
        style: {
          // @ts-expect-error
          height:
            metadata.storeItem?.variant?.desktop === StoreItemVariantDesktopEnum.THUMBNAIL_TOP
              ? extractCSSValue(webstoreLegacyTheme.storeitemCardHeight)
              : undefined,
          // @ts-expect-error
          maxHeight: extractCSSValue(webstoreLegacyTheme.storeitemCardHeight),
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            letterSpacing: extractCSSValue(webstoreLegacyTheme.storeitemDescriptionLetterSpacing),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.ffStoreItemMainSubtitle),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.itemDescriptionFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.itemDescriptionFontStyle),
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.grayColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign:
              extractCSSValue(webstoreLegacyTheme.itemDescriptionTextAlign) === 'initial'
                ? 'center'
                : metadata.storeItem?.variant?.desktop === StoreItemVariantDesktopEnum.THUMBNAIL_TOP
                ? extractCSSValue(webstoreLegacyTheme.itemDescriptionTextAlign)
                : 'left',
            // @ts-expect-error wrong key for the theme, only for legacy
            lineHeight: extractCSSValue(webstoreLegacyTheme.storeitemDescriptionLineHeight),
          },
        },
      },
      title: {
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.titlesWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign:
              extractCSSValue(webstoreLegacyTheme.storeitemTitleTextAlign) === 'initial'
                ? 'center'
                : metadata.storeItem?.variant?.desktop === StoreItemVariantDesktopEnum.THUMBNAIL_TOP
                ? extractCSSValue(webstoreLegacyTheme.storeitemTitleTextAlign)
                : 'left',
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.titlesTransformItemMainTitle),
            // @ts-expect-error wrong key for the theme, only for legacy
            letterSpacing: extractCSSValue(webstoreLegacyTheme.storeitemTitleLetterSpacing),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.ffStoreItemMainTitle),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize:
              metadata.storeItem?.variant?.desktop === StoreItemVariantDesktopEnum.THUMBNAIL_TOP
                ? extractCSSValue(webstoreLegacyTheme.storeitemTitleSize)
                : undefined,
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.storeitemTitleColor),
          },
        },
        textGradient: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: metadata.storeItem?.showTextGradient ? extractCSSValue(webstoreLegacyTheme.storeitemTextGradient) : '',
          },
        },
      },
      schedule: {
        options: {
          text: metadata.scheduleBlock?.itemText,
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign:
              extractCSSValue(webstoreLegacyTheme.itemDescriptionTextAlign) === 'initial'
                ? 'center'
                : metadata.storeItem?.variant?.desktop === StoreItemVariantDesktopEnum.THUMBNAIL_TOP
                ? extractCSSValue(webstoreLegacyTheme.itemDescriptionTextAlign)
                : 'left',
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.color),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.fontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.fontWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.fontFamily),
          },
        },
      },
      priceGroup: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          justifyContent: findCorrectAlign(extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupAlign)),
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupAlign),
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.grayColor2),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.itemPriceFontStyle),
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.itemPriceTextTransform),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontSizeDesktop),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontWeightDesktop),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontFamilyDesktop ?? webstoreLegacyTheme.ffBase),
          },
        },
      },
      orderDate: {
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.ffStoreItemMainSubtitle),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.itemDescriptionFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.itemDescriptionFontStyle),
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.grayColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign:
              extractCSSValue(webstoreLegacyTheme.itemDescriptionTextAlign) === 'initial'
                ? 'center'
                : metadata.storeItem?.variant?.mobile === StoreItemVariantMobileEnum.THUMBNAIL_TOP
                ? extractCSSValue(webstoreLegacyTheme.itemDescriptionTextAlign)
                : 'left',
          },
        },
      },
      price: {
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign: extractCSSValue(webstoreLegacyTheme.storeitemPriceAlign),
          },
        },
      },
    },
    menuItemCardMobile: {
      wrapper: {
        options: {
          boxShadow: webstoreLegacyTheme.storeitemBoxShadow,
          hoverBoxShadow: webstoreLegacyTheme.storeitemBoxShadowHover,
          showZeroPrice: metadata.storeItem?.showZeroPrice,
          showTextGradient: metadata.storeItem?.showTextGradient,
          variant: metadata.storeItem?.variant?.mobile,
          itemDescriptionFormat: {
            characterCount: webstoreLegacyTheme.itemDescriptionFormat.mobile.characterCount,
            ellipsis: webstoreLegacyTheme.itemDescriptionFormat.mobile.ellipsis,
            lineCount: webstoreLegacyTheme.itemDescriptionFormat.mobile.lineCount,
          },
        },
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.storeitemBackgroundColorMobile) || extractCSSValue(webstoreLegacyTheme.mainColor),
          border: webstoreLegacyTheme.storeitemBorder,
          ...convertShorthandBorderToFull(webstoreLegacyTheme.storeitemBorder),
          ...convertBoxShadowToRnw(extractCSSValue(webstoreLegacyTheme.storeitemBoxShadow)),
        },
      },
      wrapperHover: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          borderColor: extractCSSValue(webstoreLegacyTheme.storeitemBorderHoverColor),
          ...convertBoxShadowToRnw(extractCSSValue(webstoreLegacyTheme.storeitemBoxShadowHover)),
        },
      },
      thumbnailBlockWrapper: {
        style: {
          // @ts-expect-error
          flexBasis: extractCSSValue(webstoreLegacyTheme.storeitemImageWidthMobile),
        },
        block: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            borderRadius: extractCSSValue(webstoreLegacyTheme.storeitemImageBorderRadiusMobile),
          },
        },
      },
      content: {
        style: {
          // @ts-expect-error
          height:
            metadata.storeItem?.variant?.mobile === StoreItemVariantMobileEnum.THUMBNAIL_TOP
              ? extractCSSValue(webstoreLegacyTheme.storeitemCardHeight)
              : undefined,
          // @ts-expect-error
          maxHeight: extractCSSValue(webstoreLegacyTheme.storeitemCardHeight),
        },
        text: {
          style: {
            textAlign: metadata.storeItem?.variant?.mobile === StoreItemVariantMobileEnum.THUMBNAIL_TOP ? 'center' : 'left',
            // @ts-expect-error wrong key for the theme, only for legacy
            letterSpacing: extractCSSValue(webstoreLegacyTheme.storeitemDescriptionLetterSpacing),
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.grayColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.itemDescriptionFontSizeMobile),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.itemDescriptionFontStyle),
          },
        },
      },
      title: {
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign:
              extractCSSValue(webstoreLegacyTheme.storeitemTitleTextAlign) === 'initial'
                ? 'center'
                : metadata.storeItem?.variant?.desktop === StoreItemVariantDesktopEnum.THUMBNAIL_TOP
                ? extractCSSValue(webstoreLegacyTheme.storeitemTitleTextAlign)
                : 'left',
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize:
              metadata.storeItem?.variant?.mobile === StoreItemVariantMobileEnum.THUMBNAIL_TOP ? extractCSSValue(webstoreLegacyTheme.storeitemTitleSize) : 'm',
            // @ts-expect-error wrong key for the theme, only for legacy
            letterSpacing: extractCSSValue(webstoreLegacyTheme.storeitemTitleLetterSpacing),
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.storeitemTitleColor),
          },
        },
        textGradient: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: metadata.storeItem?.showTextGradient ? extractCSSValue(webstoreLegacyTheme.storeitemTextGradient) : '',
          },
        },
      },
      schedule: {
        options: {
          text: metadata.scheduleBlock?.itemText,
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign:
              extractCSSValue(webstoreLegacyTheme.itemDescriptionTextAlign) === 'initial'
                ? 'center'
                : metadata.storeItem?.variant?.mobile === StoreItemVariantMobileEnum.THUMBNAIL_TOP
                ? extractCSSValue(webstoreLegacyTheme.itemDescriptionTextAlign)
                : 'left',
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.color),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.fontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.fontWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.fontFamily),
          },
        },
      },
      priceGroup: {
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          justifyContent: findCorrectAlign(extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupAlign)),
        },
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupAlign),
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.grayColor2),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.itemPriceFontStyle),
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.itemPriceTextTransform),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontWeight: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontWeight),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontFamily ?? webstoreLegacyTheme.ffBase),
          },
        },
      },
      price: {
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.grayColor2),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.itemPriceFontStyle),
            // @ts-expect-error wrong key for the theme, only for legacy
            textTransform: extractCSSValue(webstoreLegacyTheme.itemPriceTextTransform),
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign: extractCSSValue(webstoreLegacyTheme.storeitemPriceAlign),
          },
        },
      },
      orderDate: {
        text: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            fontFamily: extractCSSValue(webstoreLegacyTheme.ffStoreItemMainSubtitle),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontSize: extractCSSValue(webstoreLegacyTheme.itemDescriptionFontSize),
            // @ts-expect-error wrong key for the theme, only for legacy
            fontStyle: extractCSSValue(webstoreLegacyTheme.itemDescriptionFontStyle),
            // @ts-expect-error wrong key for the theme, only for legacy
            color: extractCSSValue(webstoreLegacyTheme.grayColor),
            // @ts-expect-error wrong key for the theme, only for legacy
            textAlign:
              extractCSSValue(webstoreLegacyTheme.itemDescriptionTextAlign) === 'initial'
                ? 'center'
                : metadata.storeItem?.variant?.mobile === StoreItemVariantMobileEnum.THUMBNAIL_TOP
                ? extractCSSValue(webstoreLegacyTheme.itemDescriptionTextAlign)
                : 'left',
          },
        },
      },
    },
    categoryItemsGroup: {
      wrapper: {
        options: {
          showTitleTextGradient: metadata.menu?.showTextGradient,
        },
        title: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.backgroundCategory),
            ...getSpacingValues(webstoreLegacyTheme.categoryTitlePaddingDesktop, 'margin'),
          },
          text: {
            style: {
              textAlign: 'center',
              // @ts-expect-error wrong key for the theme, only for legacy
              fontSize: extractCSSValue(webstoreLegacyTheme.categoryTitleFontSizeDesktop),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontWeight: extractCSSValue(webstoreLegacyTheme.categoryWeight),
              // @ts-expect-error wrong key for the theme, only for legacy
              letterSpacing: extractCSSValue(webstoreLegacyTheme.letterSpacing),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontFamily: extractCSSValue(webstoreLegacyTheme.ffSecondary),
              // @ts-expect-error wrong key for the theme, only for legacy
              textTransform: extractCSSValue(webstoreLegacyTheme.titlesTransform),
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.categoryColor),
            },
          },
          textGradient: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: metadata.menu?.showTextGradient ? extractCSSValue(webstoreLegacyTheme.categoryTitleTextGradient) : '',
            },
          },
        },
        subtitle: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.categorySubtitleBackground),
            ...getSpacingValues(webstoreLegacyTheme.categorySubtitlePaddingDesktop, 'margin'),
          },
          text: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              fontSize: extractCSSValue(webstoreLegacyTheme.categorySubtitleFontSize),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontStyle: extractCSSValue(webstoreLegacyTheme.categorySubtitleFontStyle),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontWeight: extractCSSValue(webstoreLegacyTheme.categorySubtitleFontWeight),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontFamily: extractCSSValue(webstoreLegacyTheme.categorySubtitleFontFamily),
              // @ts-expect-error wrong key for the theme, only for legacy
              textTransform: extractCSSValue(webstoreLegacyTheme.categorySubtitleTextTransform),
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.categorySubtitleColor),
            },
          },
        },
        schedule: {
          options: {
            text: metadata?.scheduleBlock?.text,
          },
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.background),
          },
          text: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.color),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontSize: extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.fontSize),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontWeight: extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.fontWeight),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontFamily: extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.fontFamily),
            },
          },
        },
      },
      wrapperMobile: {
        options: {
          showTitleTextGradient: metadata.menu?.showTextGradient,
        },
        title: {
          style: {
            ...getSpacingValues(webstoreLegacyTheme.categoryTitlePadding, 'margin'),
          },
          text: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              textAlign: extractCSSValue(webstoreLegacyTheme.categoryTitleTextAlignMobile),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontSize: extractCSSValue(webstoreLegacyTheme.categoryTitleFontSize),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontWeight: extractCSSValue(webstoreLegacyTheme.categoryWeight),
              // @ts-expect-error wrong key for the theme, only for legacy
              letterSpacing: extractCSSValue(webstoreLegacyTheme.letterSpacing),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontFamily: extractCSSValue(webstoreLegacyTheme.ffSecondary),
              // @ts-expect-error wrong key for the theme, only for legacy
              textTransform: extractCSSValue(webstoreLegacyTheme.titlesTransform),
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.categoryColor),
            },
          },
          textGradient: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: metadata.menu?.showTextGradient ? extractCSSValue(webstoreLegacyTheme.categoryTitleTextGradient) : '',
            },
          },
        },
        subtitle: {
          style: {
            ...getSpacingValues(webstoreLegacyTheme.categorySubtitlePadding, 'margin'),
          },
          text: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              textAlign: extractCSSValue(webstoreLegacyTheme.categoryTitleTextAlignMobile),
            },
          },
        },
        schedule: {
          options: {
            text: metadata?.scheduleBlock?.text,
          },
          style: {},
        },
      },
    },
    topSellingItemsSlider: {
      wrapper: {
        options: {
          title: metadata?.topSellingItemsTitle,
          showTextGradient: metadata.menu?.showTextGradient,
        },
        title: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.backgroundCategory),
            ...getSpacingValues(webstoreLegacyTheme.categoryTitlePaddingDesktop, 'margin'),
          },
          text: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              fontSize: extractCSSValue(webstoreLegacyTheme.categoryTitleFontSizeDesktop),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontWeight: extractCSSValue(webstoreLegacyTheme.categoryWeight),
              // @ts-expect-error wrong key for the theme, only for legacy
              letterSpacing: extractCSSValue(webstoreLegacyTheme.letterSpacing),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontFamily: extractCSSValue(webstoreLegacyTheme.ffSecondary),
              // @ts-expect-error wrong key for the theme, only for legacy
              textTransform: extractCSSValue(webstoreLegacyTheme.titlesTransform),
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.categoryColor),
            },
          },
          textGradient: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: metadata.menu?.showTextGradient ? extractCSSValue(webstoreLegacyTheme.categoryTitleTextGradient) : '',
            },
          },
        },
        arrows: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.chevronBackgroundColorTopSellingScrollMenu),
          },
          icon: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.chevronColorTopSellingScrollMenu),
            },
          },
        },
      },
      wrapperMobile: {
        options: {
          title: metadata?.topSellingItemsTitle,
          showTextGradient: metadata.menu?.showTextGradient,
        },
        title: {
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.backgroundCategory),
            ...getSpacingValues(webstoreLegacyTheme.categoryTitlePadding, 'margin'),
          },
          text: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              fontSize: extractCSSValue(webstoreLegacyTheme.categoryTitleFontSize),
            },
          },
          textGradient: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: metadata.menu?.showTextGradient ? extractCSSValue(webstoreLegacyTheme.categoryTitleTextGradient) : '',
            },
          },
        },
      },
    },
    previousOrdersList: {
      wrapper: {
        options: {
          showPreviousOrders: metadata?.showPreviousOrders ?? false,
          title: metadata?.previousOrdersPageTitle?.trim(),
          showTextGradient: metadata.menu?.showTextGradient,
        },
        title: {
          textGradient: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: metadata.menu?.showTextGradient ? extractCSSValue(webstoreLegacyTheme.categoryTitleTextGradient) : '',
            },
          },
        },
      },
      wrapperMobile: {
        options: {
          showPreviousOrders: metadata?.showPreviousOrders ?? false,
          title: metadata?.previousOrdersPageTitle?.trim(),
          showTextGradient: metadata.menu?.showTextGradient,
        },
        title: {
          text: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              textAlign: findCorrectAlign(extractCSSValue(webstoreLegacyTheme.categoryTitleTextAlignMobile)),
            },
          },
          textGradient: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: metadata.menu?.showTextGradient ? extractCSSValue(webstoreLegacyTheme.categoryTitleTextGradient) : '',
            },
          },
        },
      },
    },
    orderReminderBanner: {
      wrapper: {
        options: {
          showOrderReminder: metadata?.showOrderReminder,
          note: metadata.customMessages?.orderReminder?.banner?.note,
        },
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.orderReminder?.summaryContainer?.background),
          ...(webstoreLegacyTheme.orderReminder?.boxShadow ? convertBoxShadowToRnw(webstoreLegacyTheme.orderReminder?.boxShadow) : {}),
        },
        confirmModal: {
          options: {
            primary: metadata.customMessages.orderReminder?.modal?.primary,
            secondary: metadata.customMessages.orderReminder?.modal?.secondary,
            success: metadata.customMessages.orderReminder?.modal?.success,
          },
          primary: {
            style: {
              ...extractAndMapToTheme(webstoreLegacyTheme.orderReminder?.modal?.primaryText),
            },
          },
          secondary: {
            style: {
              ...extractAndMapToTheme(webstoreLegacyTheme.orderReminder?.modal?.secondaryText),
            },
          },
        },
        title: {
          text: {
            style: {
              ...extractAndMapToTheme(webstoreLegacyTheme.orderReminder?.summaryContainer?.title),
            },
          },
        },
        content: {
          text: {
            style: {
              ...extractAndMapToTheme(webstoreLegacyTheme.orderReminder?.summaryContainer?.text),
            },
          },
        },
        note: {
          // @ts-expect-error wrong key for the theme, only for legacy
          style: {
            ...(extractCSSValue(webstoreLegacyTheme.orderReminder?.actionsContainer?.background)
              ? { backgroundColor: extractCSSValue(webstoreLegacyTheme.orderReminder?.actionsContainer?.background) }
              : {}),
          },
          text: {
            style: {
              ...extractAndMapToTheme(webstoreLegacyTheme.orderReminder?.actionsContainer?.text),
            },
          },
        },
      },
      wrapperMobile: {
        options: {
          showOrderReminder: metadata?.showOrderReminder,
          note: metadata.customMessages?.orderReminder?.banner?.note,
        },
        style: {
          // @ts-expect-error wrong key for the theme, only for legacy
          backgroundColor: extractCSSValue(webstoreLegacyTheme.orderReminder?.summaryContainer?.background),
        },
        confirmModal: {
          options: {
            primary: metadata.customMessages.orderReminder?.modal?.primary,
            secondary: metadata.customMessages.orderReminder?.modal?.secondary,
            success: metadata.customMessages.orderReminder?.modal?.success,
          },
          primary: {
            style: {
              ...extractAndMapToTheme(webstoreLegacyTheme.orderReminder?.modal?.primaryText),
            },
          },
          secondary: {
            style: {
              ...extractAndMapToTheme(webstoreLegacyTheme.orderReminder?.modal?.secondaryText),
            },
          },
        },
        title: {
          style: {
            ...extractAndMapToTheme(webstoreLegacyTheme.orderReminder?.summaryContainer?.title),
          },
        },
        note: {
          // @ts-expect-error wrong key for the theme, only for legacy
          style: {
            ...(extractCSSValue(webstoreLegacyTheme.orderReminder?.actionsContainer?.background)
              ? { backgroundColor: extractCSSValue(webstoreLegacyTheme.orderReminder?.actionsContainer?.background) }
              : {}),
          },
          text: {
            style: {
              ...extractAndMapToTheme(webstoreLegacyTheme.orderReminder?.actionsContainer?.text),
            },
          },
        },
        content: {
          style: {
            ...extractAndMapToTheme(webstoreLegacyTheme.orderReminder?.summaryContainer?.text),
          },
        },
      },
    },
    itemModal: {
      wrapper: {
        options: {
          openCollapseModifierGroup: metadata?.modifiers?.openCollapseModifierGroup,
        },
      },
      wrapperMobile: {
        options: {
          openCollapseModifierGroup: metadata?.modifiers?.openCollapseModifierGroup,
        },
      },
    },
    cartScreen: {
      wrapper: {
        promotionList: {
          options: {
            text: metadata.cartPromotion?.maxAmountMessage?.text,
            show: metadata.cartPromotion?.maxAmountMessage?.show,
          },
        },
        gratuity: {
          options: {
            defaultTip: {
              type: metadata.defaultTip?.type,
              amount: metadata.defaultTip?.amount,
              range: mergedConfig.cartScreen?.wrapper?.gratuity?.options?.defaultTip?.range?.length ? [] : metadata.defaultTip?.range,
            },
            customMessages: {
              title: metadata.customMessages?.gratuity?.title,
              description: metadata.customMessages?.gratuity?.description,
              notification: metadata.customMessages?.gratuity?.notification,
            },
          },
        },
        disclaimer: {
          text: {
            options: {
              text: metadata.customMessages?.orderConfirmation?.disclaimer,
            },
          },
        },
        orderTotal: {
          options: {
            showZeroDeliveryFee: metadata.showZeroDeliveryFee,
            deliveryFeeTooltip: metadata.customMessages?.selectReceiveMethod?.deliveryTooltip,
            dynamicDeliveryFeeTooltip: metadata.customMessages?.selectReceiveMethod?.dynamicDeliveryFeeTooltip,
            taxTitle: metadata.taxTitle,
          },
        },
        recommendedItems: { title: metadata?.recommendedItems?.title },
        prepTime: {
          notificationText: {
            text: metadata?.customMessages?.prepTime?.cartNotificationText,
          },
        },
        diningOptions: {
          options: {
            showNotes: metadata?.showOrderNotes ?? false,
            notesConfig: {
              default: {
                label: metadata.customMessages?.orderNotes.notesTitle ?? '',
                placeholder: metadata.customMessages?.orderNotes.notesPlaceholder ?? '',
              },
              [DiningOptionEnum.CURBSIDE]: {
                label: metadata.curbsidePickupOrderNotesTitle,
                placeholder: metadata.curbsidePickupOrderNotesPlaceholder,
              },
            },
          },
        },
        itemsList: {
          prepTimeMessage: metadata.customMessages?.prepTime?.itemPrepTime,
          itemNotesMessage: metadata.customMessages?.itemNotes?.title,
        },
        items: {
          image: {
            options: {
              defaultImage: metadata.defaultCartImage,
            },
          },
        },
        footer: {
          options: {
            closedButtonText: metadata.customMessages?.cartButtonsText?.closedButtonText,
          },
          backButton: {
            options: {
              text: metadata.customMessages?.backButtonName,
            },
          },
        },
      },
      wrapperMobile: {
        promotionList: {
          options: {
            text: metadata.cartPromotion?.maxAmountMessage?.text,
            show: metadata.cartPromotion?.maxAmountMessage?.show,
          },
        },
        gratuity: {
          options: {
            defaultTip: {
              type: metadata.defaultTip?.type,
              amount: metadata.defaultTip?.amount,
              range: mergedConfig.cartScreen?.wrapperMobile?.gratuity?.options?.defaultTip?.range?.length ? [] : metadata.defaultTip?.range,
            },
            customMessages: {
              title: metadata.customMessages?.gratuity?.title,
              description: metadata.customMessages?.gratuity?.description,
              // notification: metadata.customMessages?.gratuity?.notification,
            },
          },
        },
        disclaimer: {
          text: {
            options: {
              text: metadata.customMessages?.orderConfirmation?.disclaimer,
            },
          },
        },
        orderTotal: {
          options: {
            showZeroDeliveryFee: metadata.showZeroDeliveryFee,
            deliveryFeeTooltip: metadata.customMessages?.selectReceiveMethod?.deliveryTooltip,
            dynamicDeliveryFeeTooltip: metadata.customMessages?.selectReceiveMethod?.dynamicDeliveryFeeTooltip,
            // taxTitle: metadata.taxTitle,
          },
        },
        recommendedItems: { title: metadata?.recommendedItems?.title },
        prepTime: {
          notificationText: {
            text: metadata?.customMessages?.prepTime?.cartNotificationText,
          },
        },
        diningOptions: {
          options: {
            showNotes: metadata?.showOrderNotes ?? false,
            notesConfig: {
              default: {
                label: metadata.customMessages?.orderNotes.notesTitle ?? '',
                placeholder: metadata.customMessages?.orderNotes.notesPlaceholder ?? '',
              },
              [DiningOptionEnum.CURBSIDE]: {
                label: metadata.curbsidePickupOrderNotesTitle,
                placeholder: metadata.curbsidePickupOrderNotesPlaceholder,
              },
            },
          },
        },
        itemsList: {
          prepTimeMessage: metadata.customMessages?.prepTime?.itemPrepTime,
          itemNotesMessage: metadata.customMessages?.itemNotes?.title,
        },
        items: {
          image: {
            options: {
              defaultImage: metadata.defaultCartImage,
            },
          },
        },
        footer: {
          options: {
            closedButtonText: metadata.customMessages?.cartButtonsText?.closedButtonText,
          },
          backButton: {
            options: {
              text: metadata.customMessages?.backButtonName,
            },
          },
        },
      },
    },
    orderConfirmationScreen: {
      wrapper: {
        header: {
          title: {
            options: {
              text: metadata.orderConfirmationPageTitle,
            },
          },
        },
        packingInstructions: {
          options: {
            openCollapseModifierGroup: metadata?.modifiers?.openCollapseModifierGroup,
          },
        },
        diningOptions: {
          options: {
            pickupText: metadata.customMessages?.orderConfirmation?.orderConfirmation?.pickup,
            deliveryText: metadata.customMessages?.orderConfirmation?.orderConfirmation?.delivery,
          },
        },
        disclaimer: {
          text: metadata.customMessages?.orderConfirmation?.disclaimer,
        },
        footer: {
          backButton: {
            options: {
              text: metadata.customMessages?.backButtonName,
            },
          },
        },
      },
      wrapperMobile: {
        header: {
          title: {
            options: {
              text: metadata.orderConfirmationPageTitle,
            },
          },
        },
        packingInstructions: {
          options: {
            openCollapseModifierGroup: metadata?.modifiers?.openCollapseModifierGroup,
          },
        },
        footer: {
          backButton: {
            options: {
              text: metadata.customMessages?.backButtonName,
            },
          },
        },
      },
    },
    orderCompleteScreen: {
      wrapper: {
        content: {
          options: {
            deliveryMessage: metadata.customMessages?.orderConfirmation?.orderComplete?.delivery,
            pickupMessage: metadata.customMessages?.orderConfirmation?.orderComplete?.pickup,
            logoUrl: metadata.orderComplete?.logo,
          },
        },
      },
      wrapperMobile: {
        content: {
          options: {
            deliveryMessage: metadata.customMessages?.orderConfirmation?.orderComplete?.delivery,
            pickupMessage: metadata.customMessages?.orderConfirmation?.orderComplete?.pickup,
            logoUrl: metadata.orderComplete?.logo,
          },
        },
      },
    },
    loginRegisterScreen: {
      registrationStep: {
        subscription: {
          emailText: {
            options: {
              text: metadata.customMessages?.loginRegister?.registration?.subscription?.emailText,
            },
          },
          smsText: {
            options: {
              text: metadata.customMessages?.loginRegister?.registration?.subscription?.smsText,
            },
          },
        },
        privacyAndTerms: {
          options: {
            text: metadata.customMessages?.loginRegister?.registration?.privacyAndTermsText,
          },
        },
      },
    },
    itemScheduleBlock: {
      wrapper: {
        block: {
          title: {
            options: {
              text: metadata?.scheduleBlock?.itemModalText,
            },
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.color),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontSize: extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.fontSize),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontWeight: extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.fontWeight),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontFamily: extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.fontFamily),
            },
          },
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.background),
          },
        },
        prepTime: {
          title: {
            options: {
              text: metadata.customMessages?.prepTime?.itemPrepTime,
            },
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.color),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontSize: extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.fontSize),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontWeight: extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.fontWeight),
              // @ts-expect-error wrong key for the theme, only for legacy
              fontFamily: extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.fontFamily),
            },
          },
          icon: {
            style: {
              // @ts-expect-error wrong key for the theme, only for legacy
              color: extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.color),
            },
          },
          style: {
            // @ts-expect-error wrong key for the theme, only for legacy
            backgroundColor: extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.background),
          },
        },
      },
    },
    customNavigationLinks: webMerchant.customNavigationLinks,
  };

  return deepmerge(legacyConfig, mergedConfig);
};
