import type { PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';

import { media } from '../../../shared/utils/media-queries';

interface Props {
  className?: string;
}

export const CircleBadge: React.FC<PropsWithChildren<Props>> = ({ children, className = '' }) => <Container className={className}>{children}</Container>;

const Container = styled.div`
  position: absolute;
  top: -4px;
  right: -10px;
  font-size: 10px;
  width: 15px;
  height: 15px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  background: var(--cart-header-count-bg);
  color: var(--cart-header-count-color);

  .modal__actions & {
    position: static;
    font-size: 12px;
    line-height: 20px;
    width: 20px;
    height: 20px;
  }

  .header-component__mobile-button & {
    top: -4px;
    right: -5px;
    width: 12px;
    height: 12px;
  }

  .header-component__cart & {
    width: 20px;
    height: 20px;
    left: 15px;
    top: -10px;
    font-weight: var(--header-font-weight);
    line-height: 20px;
    font-size: 13px;

    ${media.tablet} {
      left: 20px;
      top: -5px;
    }
  }
`;
