import styled, { css } from 'styled-components';

import { media } from '../../shared/utils/media-queries';

export const Container = styled.div<{ isMinimized: boolean }>`
  ${({ isMinimized }) => css`
    position: fixed;
    right: 16px;
    left: 16px;
    bottom: 16px;
    background: white;
    z-index: 99;

    ${media.tablet} {
      width: 343px;
      left: unset;
    }

    ${isMinimized &&
    css`
      border-radius: 0;
      height: 48px;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      ${media.tablet} {
        width: 100%;
      }
    `}
  `}
`;
