import type { IReadAbstractStoreScheduleDto } from '@goparrot/store-v2-sdk';
import { HumanReadableUtils } from '@goparrot/store-v2-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { TemplateTypes } from '@webstore-monorepo/shared/enums';
import get from 'lodash/get';
import type { ReactNode } from 'react';
import React from 'react';

import { HeroBlockText } from '../hero-block-text';
import { ScheduleTemplateComponent } from '../shedule-template';

export const CustomDescription: React.FC = () => {
  const store = useStoreState();
  const { heroBlock } = useComponentsConfig();
  const { customDescription } = heroBlock?.wrapper?.options ?? {};
  const { haveCustomSchedule } = store.delivery ?? {};
  const { haveDineInHours } = store.schedule ?? {};

  // Used to handle cases when customDescription may be an string[];
  const parsedCustomDescription = Array.isArray(customDescription) ? customDescription.join(' ') : customDescription;
  const TemplatesDefinition = {
    weeklySchedule: {
      type: TemplateTypes.SCHEDULE,
      path: 'schedule.weekly',
    },
    weeklyDeliverySchedule: {
      type: TemplateTypes.SCHEDULE,
      path: haveCustomSchedule ? 'delivery.weeklySchedule' : 'schedule.weekly',
    },
    dineInSchedule: {
      type: TemplateTypes.SCHEDULE,
      path: 'schedule.dineIn',
    },
    restaurantAddress: {
      type: TemplateTypes.TEXT,
      path: 'location.formattedAddress',
    },
    phoneNumber: {
      type: TemplateTypes.TEXT,
      path: 'contactInfo.phone',
    },
  };

  // @ts-ignore
  const matchedTemplates = [...(parsedCustomDescription?.matchAll(/%([\S\s]*?)%/g) ?? [])];

  return (
    <>
      {matchedTemplates.map((template: RegExpMatchArray) => {
        const templateKey = template[1];

        if (templateKey === 'dineInSchedule' && !haveDineInHours) {
          return '';
        }
        const getTemplate = TemplatesDefinition[templateKey as keyof typeof TemplatesDefinition];

        if (getTemplate) {
          const data = get(store, getTemplate.path, null) as unknown as IReadAbstractStoreScheduleDto;
          if (getTemplate.type === TemplateTypes.SCHEDULE) {
            return <ScheduleTemplateComponent key={template.index} schedule={HumanReadableUtils.getWeeklyWorkingHours(data)} />;
          }
          if (getTemplate.type === TemplateTypes.TEXT) {
            return (
              <HeroBlockText key={template.index} {...heroBlock?.descriptionBlock?.text?.style} fontWeight="400" fontStyle="italic">
                {data as ReactNode}
              </HeroBlockText>
            );
          }
        }
        return (
          <HeroBlockText
            {...heroBlock?.descriptionBlock?.text?.style}
            key={template.index}
            fontWeight="400"
            fontStyle="italic"
            {...heroBlock?.schedule?.plainText?.style}
          >
            {templateKey}
          </HeroBlockText>
        );
      })}
    </>
  );
};
