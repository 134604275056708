import { PromoPopup as RNWPromoPopup } from '@webstore-monorepo/features/promo-popup';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Popup } from '../../../../components/uielements/popup';
import { useAppContextDispatch } from '../../../../shared/contexts/app-context-provider';
import { useAnalytics } from '../../../../shared/hooks/use-analytics';
import { useAppHistory } from '../../../../shared/hooks/use-app-history';
import { startAddingManualPromo } from '../../../../shared/utils/promotions';
import { MENU_PATHS } from '../../../paths';
import { usePromoPopupState } from './hooks';

export const PromoPopup: React.FC = () => {
  const location = useLocation();
  const { storeId } = useStoreState();
  const appContextDispatch = useAppContextDispatch();
  const currentRoute = location.pathname;
  const { setAsViewedPromo, setAsRejected, newApplicablePromos, onHold } = usePromoPopupState();
  const analytics = useAnalytics();
  const history = useAppHistory();
  const [popupPromo] = newApplicablePromos;
  const { promotion, availableApplicationCount } = popupPromo ?? {};
  const amountOfNewApplicablePromos = newApplicablePromos.length;

  const handleAddToCart = () => {
    appContextDispatch({ type: 'update', payload: { promotion: popupPromo } });
    startAddingManualPromo(promotion, history, storeId, availableApplicationCount);
    setAsViewedPromo(promotion.uuid);
  };

  const handleCancel = () => {
    analytics.track('popup_promo_refused', { uuid: promotion.uuid });

    setAsRejected(promotion.uuid);
  };

  return (
    <Popup show={!onHold && MENU_PATHS.promos !== currentRoute && !!amountOfNewApplicablePromos} onClose={handleCancel}>
      {() => <RNWPromoPopup onCancel={handleCancel} onAddToCart={handleAddToCart} promotion={promotion} />}
    </Popup>
  );
};
