import { StoreItemTypeEnum, StoreItemValidityEnum } from '@goparrot/common';
import type { IStoreItemOptionSelectionsProperty } from '@goparrot/storeitems-sdk';
import { Checkbox } from '@webstore-monorepo/ui/checkbox';
import DOMPurify from 'dompurify';
import type { MouseEvent } from 'react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getFormattedNumber } from '../../../shared/utils/intl';
import { LazyImage } from '../images/LazyImage';
import type { SelectionObj } from './interfaces';
import * as s from './Selection.styled';

export interface Props {
  index?: number;
  showZeroPrice?: boolean;
  selection: SelectionObj;
  onChange(event?: any): void;
  onItemBaseGroupClick?: (prop: IStoreItemOptionSelectionsProperty) => void;
  onDeselectItemBaseGroupClick?: () => void;
}

export const Selection: React.FC<Props> = ({ index = 0, selection, showZeroPrice, onChange, onItemBaseGroupClick, onDeselectItemBaseGroupClick }) => {
  const { uid, title, validity, titleHTML, type, selected, imageUrl, unselected_order, price, unselected_icon, selectedItem, properties } = selection;
  const [showBaseGroups, setShowBaseGroups] = useState(false);

  const handleShow = () => setShowBaseGroups(true);

  const handleHide = () => setShowBaseGroups(false);

  const handleItemBaseClick = (prop: IStoreItemOptionSelectionsProperty) => () => {
    onItemBaseGroupClick && onItemBaseGroupClick(prop);
    handleHide();
  };

  const handleLabelClick = (e: MouseEvent<HTMLLabelElement>) => {
    onChange(e);
  };

  const handleDeselectItemBaseGroupClick = () => {
    onDeselectItemBaseGroupClick && onDeselectItemBaseGroupClick();
    handleHide();
  };

  return (
    <s.CheckboxContainer
      data-testid={`selection-item-container-${uid}`}
      invalid={validity === StoreItemValidityEnum.MISSING_ITEM}
      isItemBaseGroup={type === StoreItemTypeEnum.ITEM_BASE_GROUP}
      isGroupShown={showBaseGroups}
    >
      {validity && StoreItemValidityEnum.VALID !== validity && (
        <s.SelectionLabel unavailable htmlFor={uid}>
          {titleHTML && (
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(titleHTML) }}>
              {titleHTML}
              <s.SelectionLabelSubtitle>
                <FormattedMessage id="error.itemUnavailable" />
              </s.SelectionLabelSubtitle>
            </span>
          )}
          {!titleHTML && (
            <span>
              {title}
              <s.SelectionLabelSubtitle>
                <FormattedMessage id="error.itemUnavailable" />
              </s.SelectionLabelSubtitle>
            </span>
          )}
        </s.SelectionLabel>
      )}
      {(!validity || StoreItemValidityEnum.VALID === validity) && (
        <>
          {type !== StoreItemTypeEnum.ITEM_BASE_GROUP && (
            <>
              <s.LabelThumbnail htmlFor={uid}>
                {imageUrl && <LazyImage imageSrc={imageUrl} width={180} className="img-width-100" alt={title} />}
              </s.LabelThumbnail>
              <s.CheckBoxLabelContainer>
                <Checkbox
                  id={uid}
                  data-testid={`selection-item-base-${uid}`}
                  checked={selected}
                  isDisabled={selection.disabled}
                  onPress={onChange}
                  labelPlacement="after"
                />
                <s.SelectionLabel onClick={handleLabelClick} htmlFor={uid}>
                  {titleHTML && <s.SelectionTitle dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(titleHTML) }} />}
                  {!titleHTML && <s.SelectionTitle>{title}</s.SelectionTitle>}
                  {(price || showZeroPrice) && <s.SelectionPrice>{getFormattedNumber(price)}</s.SelectionPrice>}
                </s.SelectionLabel>
              </s.CheckBoxLabelContainer>
            </>
          )}
          {StoreItemTypeEnum.ITEM_BASE_GROUP === type && (
            <div>
              <s.SelectionGroup>
                <s.SelectionGroupProperties onClick={handleShow} isClickable={!showBaseGroups}>
                  <s.SelectGroupSignal>
                    {selectedItem?.icon_selected && (
                      <s.SelectionGroupItem data-testid="group-select-selected-item">
                        <LazyImage key={selectedItem?.icon_selected} className="img-width-100" imageSrc={selectedItem?.icon_selected} alt={title} noFilter />
                      </s.SelectionGroupItem>
                    )}
                    {!selectedItem?.icon_selected && unselected_icon && (
                      <s.SelectionGroupItem data-testid="group-select-unselect-icon">
                        <LazyImage className="img-width-100" imageSrc={unselected_icon} alt={title} noFilter />
                      </s.SelectionGroupItem>
                    )}
                  </s.SelectGroupSignal>
                  <s.PropertyTitle>{title}</s.PropertyTitle>
                </s.SelectionGroupProperties>
                <s.SelectionPrice>{getFormattedNumber(price)}</s.SelectionPrice>
              </s.SelectionGroup>
              <s.SelectGroup isVisible={showBaseGroups}>
                {properties?.map((prop) => (
                  <s.SelectionGroupItem
                    key={prop.uid}
                    select
                    data-testid={`select-group-items-${prop.uid}`}
                    onClick={handleItemBaseClick(prop)}
                    order={index + 1}
                  >
                    {prop.icon && <LazyImage className="img-width-100" imageSrc={prop.icon} alt={title} noFilter />}
                  </s.SelectionGroupItem>
                ))}
                {unselected_icon && (
                  <s.SelectionGroupItem
                    data-testid="select-group-items-unselect"
                    select
                    unselected
                    order={unselected_order}
                    onClick={handleDeselectItemBaseGroupClick}
                  >
                    <LazyImage className="img-width-100" imageSrc={unselected_icon} alt={title} noFilter />
                  </s.SelectionGroupItem>
                )}
              </s.SelectGroup>
            </div>
          )}
        </>
      )}
    </s.CheckboxContainer>
  );
};
