import { UserTypeEnum } from '@goparrot/customer-sdk';
import { DiningOptionInfoUtil, OrderStatusEnum } from '@goparrot/order-sdk';
import { ConfirmArrival, OrderReminderBanner as OrderReminderBannerRNW } from '@webstore-monorepo/features/order-reminder-banner';
import { useLastOrders } from '@webstore-monorepo/shared/api/cart-api';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { parseURLSearchParams } from '@webstore-monorepo/shared/utils/url';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import { ConfirmModal } from '../uielements/confirm-modal';
import * as s from './OrderReminderBanner.styled';

const urlParams = parseURLSearchParams();
const areOrderSpecificUrlParams = urlParams?.confirmArrival && urlParams?.orderNumber;

export const OrderReminderBanner: React.FC = () => {
  const userContext = useUserContextState();
  const showPreviousOrders = userContext?.user?.type === UserTypeEnum.AUTHENTICATED;
  const { data: lastOrders = [] } = useLastOrders({ enabled: showPreviousOrders });
  const [isModalActive, setModalState] = useState(false);
  const [isMinimized, setMinimizedState] = useState(false);
  const [isArrivalConfirmed, setConfirmArrivalState] = useState(false);

  const activeOrder = [...(lastOrders ?? [])]
    .sort((currentOrder, nextOrder) => new Date(currentOrder.dateInfo?.fulfilledAt).getTime() - new Date(nextOrder.dateInfo?.fulfilledAt).getTime())
    .find((order) => {
      if (!DiningOptionInfoUtil.isCurbsideDiningOptionInfo(order.diningOptionInfo) || order.diningOptionInfo.arrivedAt) {
        return false;
      }

      const fulfilledAt = DateTime.fromISO(order.dateInfo?.fulfilledAt as string);
      const isToday = DateTime.now().hasSame(fulfilledAt, 'day');
      if (!isToday) {
        return false;
      }

      if (areOrderSpecificUrlParams) {
        return urlParams.orderNumber === order.orderNumber;
      }

      return order.status && [OrderStatusEnum.RECEIVED, OrderStatusEnum.READY].includes(order.status);
    });

  const toggleMinimizedState = () => setMinimizedState(!isMinimized);
  const openModal = () => setModalState(true);
  const closeModal = () => setModalState(false);
  const confirmArrival = () => setConfirmArrivalState(true);

  useEffect(() => {
    if (activeOrder && DiningOptionInfoUtil.isCurbsideDiningOptionInfo(activeOrder.diningOptionInfo)) {
      setConfirmArrivalState(!!activeOrder?.diningOptionInfo?.arrivedAt);
    }
  }, [activeOrder]);

  useEffect(() => {
    if (!isArrivalConfirmed && areOrderSpecificUrlParams) {
      openModal();
    }
  }, [isArrivalConfirmed]);

  if (!activeOrder) {
    return null;
  }

  return (
    <s.Container isMinimized={isMinimized}>
      <OrderReminderBannerRNW
        activeOrder={activeOrder}
        isArrivalConfirmed={isArrivalConfirmed}
        isMinimized={isMinimized}
        onMinimize={toggleMinimizedState}
        onMaximize={toggleMinimizedState}
        onShowDetails={openModal}
      />
      {isModalActive && (
        <ConfirmModal onClose={closeModal}>
          <ConfirmArrival
            onClose={closeModal}
            onConfirm={confirmArrival}
            orderId={activeOrder?.uuid ?? ''}
            firstName={userContext?.user?.profile?.first_name}
          />
        </ConfirmModal>
      )}
    </s.Container>
  );
};
