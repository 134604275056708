import type { WeeklyWorkingSchedule } from '@goparrot/store-v2-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { makeSundayLast } from '@webstore-monorepo/shared/utils/store';
import { Box } from '@webstore-monorepo/ui/box';
import React from 'react';

import { ScheduleItem } from './ScheduleItem';

export interface ScheduleTemplate {
  schedule: WeeklyWorkingSchedule[];
  description?: string;
}

export const ScheduleTemplateComponent: React.FC<ScheduleTemplate> = ({ schedule, description }) => {
  const formattedSchedule = makeSundayLast(schedule);
  const { heroBlock } = useComponentsConfig();

  return (
    <Box
      testID={description ? 'html-injection' : 'schedule-wrapper'}
      {...(!description
        ? {
            ...heroBlock?.schedule?.style,
          }
        : {})}
    >
      {formattedSchedule.map((hours: WeeklyWorkingSchedule) => (
        <ScheduleItem key={`${hours.dayFrom}-${hours.dayTo}`} hours={hours} description={description} />
      ))}
    </Box>
  );
};
